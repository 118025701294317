import { graphql, useStaticQuery } from 'gatsby'

export interface StrapiTopBar {
  description: string
}

export const useQueryTopbar = (): StrapiTopBar => {
  const { strapiTopBar }: { strapiTopBar: StrapiTopBar } = useStaticQuery(graphql`
    query {
      strapiTopBar {
        description
      }
    }
  `)

  return strapiTopBar
}
